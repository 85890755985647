<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Toolbar class="mb-4">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Filtros" icon="pi pi-plus" class="p-button-success mr-2"
                                @click="abrirFiltros" />

                        </div>
                    </template>

                    <template v-slot:end>
                        <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
                        <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
                    </template>
                </Toolbar>

                <!-- Tabela de Implantaçoes -->
                <DataTable ref="dt" :value="faturas" v-model:expandedRows="selectImplantacoes" dataKey="id"
                    :paginator="true" :rows="20" :filters="filters"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                    responsiveLayout="scroll">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Fila de Implantação</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>

                    <Column select style="width: 5rem" />

                    <Column field="id" header="Id" :sortable="true" headerStyle="width:5%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Id</span>
                            {{ slotProps.data.id }}
                        </template>
                    </Column>
                    <Column field="razao_social" header="Empresa" :sortable="true"
                        headerStyle="width:20%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Empresa</span>
                            {{ slotProps.data.razao_social }}
                        </template>
                    </Column>
                    
                    <Column field="quantidade_arquivos" header="QTD Arquivos Enviadoss" :sortable="true"
                        style="text-align: center;" headerStyle="width:20%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">QTD Arquivos Enviados</span>

                            <Badge :value="slotProps.data.quantidade_arquivos" style="background-color: #666;"></Badge>

                        </template>
                    </Column>
                    <Column field="status" header="Status" :sortable="true" headerStyle="width:20%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Status</span>

                            <Tag v-if="slotProps.data.status == 0" style="background-color: #999;"
                                :value="status_array[slotProps.data.status]"></Tag>

                            <Tag v-if="slotProps.data.status == 1" style="background-color: #999;"
                                :value="status_array[slotProps.data.status]"></Tag>

                            <Tag v-if="slotProps.data.status == 2" style="background-color: #999;"
                                :value="status_array[slotProps.data.status]"></Tag>

                            <Tag v-if="slotProps.data.status == 3" style="background-color: #999;"
                                :value="status_array[slotProps.data.status]"></Tag>

                            <Tag v-if="slotProps.data.status == 4" style="background-color: #999;"
                                :value="status_array[slotProps.data.status]"></Tag>

                            <Tag v-if="slotProps.data.status == 5" style="background-color: darkseagreen;"
                                :value="status_array[slotProps.data.status]"></Tag>

                            <Tag v-if="slotProps.data.status == 6" style="background-color: darkseagreen;"
                                :value="status_array[slotProps.data.status]"></Tag>

                                <Tag v-if="slotProps.data.status == 8" style="background-color: red;"
                                :value="'Erro ao Processar'"></Tag>
                        </template>
                    </Column>

                    <Column field="data_fila" header="Data Atualização" :sortable="true"
                        headerStyle="width:30%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Data Atualização</span>
                            {{ slotProps.data.data_fila }}
                        </template>
                    </Column>
                </DataTable>

                <Dialog v-model:visible="ModalFiltros" :style="{ width: '600px' }" header="Filtros" :modal="true"
                    class="p-fluid">
                    <div class="field">
                        <label for="id_status">Empresa</label>
                        <Dropdown id="id_status" filter required="true" v-model="filtros.id_status"
                            :options="tipos_status" optionLabel="label" optionValue="value"
                            placeholder="Selecione uma empresa"></Dropdown>
                    </div>


                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
                            @click="this.ModalFiltros = false" />
                        <Button label="Pesquisar" icon="pi pi-check" class="p-button-success p-button-sm"
                            @click="pesquisar()" />
                    </template>
                </Dialog>



            </div>
        </div>
    </div>
</template>

<script>

import { FilterMatchMode } from "primevue/api";
import funcoes from '../../funcoes.js';

export default {
    mixins: [funcoes],
    data() {
        return {
            filtros: [],
            ModalFiltros: false,
            faturas: [],
            fatura: {},
            selectImplantacoes: null,
            ModalFatura: false,

            medidor: {},
            modalEditMedidor: false,
            medidorDialog: false,

            itens_faturados: {},
            modalEditItens: false,

            dados_faturas: {},
            modalEditDados: false,

            dados_fiscais: {},
            modalEditDadosFiscais: false,

            dados_tributos: {},
            modalEditDadosTributos: false,

            deleteModalFatura: false,
            deleteMedidorDialog: false,
            deleteDadosDialog: false,
            deleteDadosFiscaisDialog: false,
            deleteDadosTributosDialog: false,
            deleteItensFaturadosDialog: false,
            deleteProductsDialog: false,

            filters: {},
            submitted: false,

            tipos_status: [
                { label: "Processo Iniciado", value: 0 },
                { label: "Separação Iniciada", value: 1 },
                { label: "Separação Finalizada", value: 2 },
                { label: "Extração Iniciada", value: 3 },
                { label: "Extração Finalizada", value: 4 },
                { label: "Implantação Finalizada", value: 5 },
                { label: "Implantação Finalizada", value: 6 },
            ],

            tipos_empresas: [],
            desc_tipos_empresas: [],
            tipo_insumo: [],
            desc_tipo_insumo: [],
            tipos_modalidades: [],
            tipos_concess: [],
            tipos_pagamentos: [],
            tipos_classe_tarifaria: [],
            tipos_subclasse: [],
            tipos_tensao: [],
            tipos_ligacao: [],
            status_array: [
                'Upload Iniciado',
                'Separação Iniciada ',
                'Separação Finalizada',
                'Extração Iniciada',
                'Extração Finalizada',
                'Implantação Finalizada',
                'Implantação Finalizada'
            ]

        };
    },

    created() {
        this.initFilters();
    },
    mounted() {
        this.listarFila();

        // this.intervalId = setInterval(() => {
        //     this.listarFila();
        // }, 1000);
        //5000
    },


    methods: {
        listarFila() {
            this.axios
                .post("/faturas/fila/listar")
                .then((response) => {
                    this.faturas = response.data.dados;
                    console.log(this.faturas)
                })
                .catch(() => {
                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        openNew() {
            this.fatura = {};
            this.submitted = false;
            this.ModalFatura = true;
        },

        abrirFiltros() {
            this.ModalFiltros = true;
        },

        editarFatura(fatura) {
            this.ModalFatura = true;
            this.fatura = { ...fatura };
        },

        editDadosMedidor(medidor) {
            this.modalEditMedidor = true;
            this.medidor = { ...medidor };
        },

        editDadosFiscais(dados_fiscais) {
            this.modalEditDadosFiscais = true;
            this.dados_fiscais = { ...dados_fiscais };
        },

        editDadosTributos(dados_tributos) {
            this.modalEditDadosTributos = true;
            this.dados_tributos = { ...dados_tributos };
        },

        editItensFaturados(itens_faturados) {
            this.modalEditItens = true;
            this.itens_faturados = { ...itens_faturados };
        },

        editDadosFatura(dados_faturas) {
            this.modalEditDados = true;
            this.dados_faturas = { ...dados_faturas };
        },

        hideDialog() {
            this.ModalFatura = false;
            this.submitted = false;
        },

        addDadosMedidor(medidor) {
            this.modalEditMedidor = true;
            this.medidor.id_fatura = medidor.id;
        },

        addItensFaturados(itens_faturados) {
            this.modalEditItens = true;
            this.itens_faturados.id_fatura = itens_faturados.id;
        },


        exportCSV() {
            this.$refs.dt.exportCSV();
        },


        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },

    },
};


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>